<template>
  <div class="page-product-details-box">
    <PageTop1></PageTop1>
    <PageTop></PageTop>
    <div class="product-top-box">
      <div class="product-left">
        <!-- 商品图片介绍 -->
        <div class="product-img-introduce">
          <!-- 商品图片和学术资料轮播 -->
          <div class="img-box-single" ref="smallBox">
            <div class="product-img" ref="smallBoxWrap">
              <div class="small-box" :style="{ 'pointer-events': isOnHover ? 'none' : '' }">
                <div class="float-box" ref="objFloatBox" v-if="isShowfloat"></div>
                  <div v-if="pageDataSource.learningUrlList && pageDataSource.learningUrlList.length > 0">
                      <el-carousel height="293px" :interval="4000" class="small-product-img">
                          <el-carousel-item v-for="(item,i) in pageDataSource.learningUrlList" :key="i" >
                              <div @mouseenter.stop="onMouseMaskIn($event,item)" @mouseleave.stop="onMouseMaskOut($event,item)"
                                   @mousemove.stop="onMouseMaskMove($event,item)">
                                  <el-image :src="item" :preview-src-list="pageDataSource.learningUrlList"></el-image>
                              </div>
                          </el-carousel-item>
                      </el-carousel>
                  </div>
                  <img :src="defaultImg" alt class="small-product-img" v-else />
              </div>
              <div class="big-box" ref="objBigBox" v-if="isShowBigBox">
                <img :src="bigPicture" alt ref="objBigBoxImage" />
              </div>
            </div>
            <!--零差价标识-->
            <div class="zero-price-img" v-if="pageDataSource.isZeroPriceDifference == 1">
              <img src="@images/PC0.png" />
            </div>
            <div class="rebate" v-if="pageDataSource.productAttribute == '1'">
              <font class="product-hbimg-jf">返利</font>
            </div>
            <img v-if="pageDataSource.productPatent && pageDataSource.productPatent == '1'"
              class="db_icon"
              src="https://obs.pujian.com/frontend/app/components/db_icon.png"
              alt />
            <div class="bg-sold" v-if="invalidText.length > 0 && isLogin">
              <span>{{ invalidText }}</span>
            </div>
            <div class="bg-sold" v-if="videodata.videoId" style="background-color:rgba(51, 51, 51, 0.2);">
              <div class="center-video" @click.stop="isstate = true"></div>
              <div class="center-text" v-if="!pageDataSource.isView">观看视频领{{ videodata.redbagMoney }}元</div>
            </div>
              <div :class="['rgPs', pageDataSource.isLearning && pageDataSource.isLearning === 1 && pageDataSource.areaExclusiveProductCategory === 0 ? 'xsPs' : '']" v-if="pageDataSource.areaExclusiveProductCategory === 1 || (pageDataSource.isLearning && pageDataSource.isLearning === 1)">
                  {{ pageDataSource.areaExclusiveProductCategory === 1 ? '区域独家' : pageDataSource.isLearning && pageDataSource.isLearning === 1 ? '学术' : '' }}
              </div>
          </div>
        </div>

        <!-- 商品文字信息介绍 -->
        <div class="product-info-introduce">
          <p class="product-name-box">
            <span class="prescription-cls" :style="{ background: pageDataSource.prescription.bgColor }"
              v-if="pageDataSource.prescription && pageDataSource.prescription.text">
              {{ pageDataSource.prescription.text }}
            </span>

            <img class="tail-hb-img" src="@images/2.gif" v-if="pageDataSource.activityVOList != undefined &&
              pageDataSource.activityVOList.length > 0 &&
              pageDataSource.activityVOList[0].isIntegralCommodity &&
              pageDataSource.activityVOList[0].isIntegralCommodity == 1
              " />
            {{ pageDataSource.productName }}【{{ pageDataSource.specifications }}】
          </p>
          <productLabels :itemObject="pageDataSource"/>
          <!-- 价格和优惠活动 -->
          <div class="product-active-price" style="text-align: left">
            <!--商品价格组件-->
            <ProductPriceItem
              v-if="pageDataSource.activityVo && pageDataSource.activityVo.activityStrategyReduceVOS && pageDataSource.activityVo.activityStrategyReduceVOS.length"
              :key="pageDataSource.id"
              :product="pageDataSource"
              productType="productList"
            />

            <!--非特价品种-->
            <div class="label-box" style="align-items: center;" v-else>
              <p class="label-purchase">采购价格</p>
              <p>
                <span class="symbol">¥</span>
                <span class="purchase-price" v-if="!pageDataSource.preferenticePrice">价格登录后可见</span>
                <span class="purchase-price" v-if="isExternal">价格不可见</span>
                <span class="purchase-price" v-if="pageDataSource.preferenticePrice && !isExternal">
                  {{ pageDataSource.preferenticePrice }}
                  <span v-if="pageDataSource.sellUnit">{{ "/" + pageDataSource.sellUnit }}</span>
                </span>
              </p>
            </div>

            <div class="label-box">
              <p style="flex: 1;">
                <span style="margin-right: 16px;" v-if="pageDataSource.medicalInsurancePrice">
                  医保价: ￥{{ pageDataSource.medicalInsurancePrice }}/{{ pageDataSource.medicalUnit }}
                </span>
                <span v-if="pageDataSource.medicalInsurancePrice">
                  医保价差: ￥{{ pageDataSource.medicalDifferencePrice }}
                </span>
                <span v-else>零售价: ¥{{ pageDataSource.suggestedPrice }}</span>
                <span v-if="pageDataSource.sellUnit">{{ '/' + pageDataSource.sellUnit }}</span>
                <span style="margin-left: 16px;">毛利率{{ pageDataSource.grossProfitRate }}%</span>
              </p>
            </div>

            <div class="special-offer-activity" v-if="pageDataSource.isActivityGood">
                <div>{{ pageDataSource.activityGoodInfo.an1_l }},<span class="rd">{{ pageDataSource.activityGoodInfo.an1_r }}</span></div>
                <div v-if="pageDataSource.activityGoodInfo.an2_l">
                  <span>{{ pageDataSource.activityGoodInfo.an2_l }},
                    <span class="rd">{{ pageDataSource.activityGoodInfo.an2_r }}</span>
                  </span>
                </div>
            </div>

            <div class="label-box">
              <!--商品的活动标签-->
              <ProductActivityTags :key="pageDataSource.id" :product="pageDataSource" />
            </div>
            <img class="isControl" v-if="pageDataSource.factoryControl === 1" src="https://obs.pujian.com/frontend/app/controlSell/controlSell.png" />
          </div>
          <div class="product-info-basic-box">
            <ul>
              <li>
                <span>生产厂家：</span>
                <span>{{ pageDataSource.produceFactory | ellipsisss }}</span>
              </li>
              <li>
                <span style="color: #3875f6; width: 85px">最后退货时间：</span>
                <span style="color: #3875F6;">{{ pageDataSource.lastReturnTime || '' }}</span>
              </li>
              <li>
                <span>效期：</span>
                <span v-if="pageDataSource.nearEffectivePeriod">
                  {{ pageDataSource.nearEffectivePeriod | formatDate }}
                </span>
                <span v-else></span>
              </li>
              <li v-if="Number(pageDataSource.isMediumPackage) === 1">
                <span>中包装 (1中包装={{ pageDataSource.mediumPackage }}{{ pageDataSource.sellUnit }})</span>
              </li>
              <li>
                <span v-if="isLogin && !isExternal && pageDataSource.isFactoryAndBelongProductFactory == 0">
                  库存：{{ pageDataSource.availableNum > 99 ? "99+" : pageDataSource.availableNum }}
                </span>
                <span v-if="isLogin && !isExternal && pageDataSource.isFactoryAndBelongProductFactory == 1">
                  库存：{{ pageDataSource.availableNum }}
                </span>
              </li>
              <li>
                <span>件包装：{{ pageDataSource.packageNumber }}{{ pageDataSource.sellUnit }}</span>
              </li>
            </ul>
            <div style="text-align: left; margin-top: 14px;">
              <span class="dn-txt" @click="toDownLoad">下载产品资料</span>
            </div>
          </div>
          <div class="product-num-box" v-if="pageDataSource.factoryControl === 0">
            <div style="position: relative; height: 36px">
              <p class="medium-packaging" v-if="Number(pageDataSource.isMediumPackage) === 1">
                实际购买<span>{{ Number(detailsData.totalNumber) * pageDataSource.mediumPackage }}</span>
                <span>{{ pageDataSource.sellUnit }}</span>
              </p>
              <NumberInput :detailsData="detailsData" :pageDataSource="pageDataSource" @isDetailsData="isDetailsData"
                @handleSign="handleSign(detailsData)" />
            </div>
            <div class="to-shop-box">
              <div class="button-box">
                <button class="btn" type="button" @click="addCart" :disabled="disabledAddCart ||
                  detailsData.totalNumber == 0 ||
                  !pageDataSource.isInvalid ||
                  isDetai == '0' ||
                  isExternal">
                  <img src="@/assets-v2/images/sy_gouwuche.png" style="width: 28px" />
                  <span style="cursor: pointer">加入购物车</span>
                </button>
              </div>
              <div class="stockout" v-if="pageDataSource.availableNum == 0 &&
                pageDataSource.isCheckArrive == 0
                " @click="handelStockout(pageDataSource)">
                <p>点击到货提醒</p>
              </div>
              <div class="stockout disabled" v-if="pageDataSource.isCheckArrive == 1"
                @click="deleteRemind(pageDataSource)">
                <p>取消提醒</p>
              </div>
            </div>
            <!-- 收藏 -->
            <div class="enshrine" v-if="!isExternal">
              <div class="enshrine-img" @click="hanndleEnshrine">
                <img v-if="!pageDataSource.isCollect" src="./images/xq_weishoucang.png" alt="" />
                <img v-if="pageDataSource.isCollect" src="./images/xq_yishoucang.png" alt="" />
              </div>
              <div class="enshrine-text" @click="hanndleEnshrine">
                {{ pageDataSource.isCollect ? "已收藏" : "未收藏" }}
              </div>
            </div>
          </div>
            <div  class="isControlSale" v-else @click="lookPhone(pageDataSource)">
                <div class="isControlSale_btn">查看代理联系方式</div>
            </div>
          <div class="purchase-tips">
            温馨提示：图片均为原品的真实拍摄，图片仅供参考。如遇新包装上市可能存在更新滞后，请以实物为准
          </div>
          <p class="xg-tips" v-if="pageDataSource.specialCommodities">
            {{pageDataSource.specialCommodities}}本商品
            <span class="xg-rd">不可网络销售</span>，可联系开票员下单
            <span class="xg-phone" v-if="pageDataSource.specialCommodities">{{pageDataSource.contactNumber ? pageDataSource.contactNumber : '023-88079999'}}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="product-info-box">
      <b-tabs v-model="activeTab" type="is-boxed">
        <b-tab-item label="规格参数">
          <div class="list-info-box">
            <div class="info-every" v-for="(item, index) in infoLabelList" :key="index">
              <p>{{ item.label }}</p>
              <p v-if="item.props != 'productCode'">{{ pageDataSource[item.props] }}</p>
              <p v-if="item.props == 'productCode'"></p>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="商品说明书">
          <div id="detail" class="instruction-cls" v-if="instructionList.content">
            {{ instructionList.content }}
          </div>
          <div class="noInfoList" v-else>
            <div class="empty-list">
              <img src="@/assets-v2/images/pic_empty.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>

    <div class="product-relation" v-if="detailsData.hotProduct.length">
      <div class="title-hot">商品推荐</div>
      <ul class="product-box">
        <li v-for="(item, index) in detailsData.hotProduct" :key="index">
          <!--新的商品列表-->
          <!--<ProductItem :product="item" />-->

          <!--历史商品列表-->
          <historicalProductList :product="item" />
        </li>
      </ul>
    </div>

    <PageFooter></PageFooter>

    <div class="success-bg" v-if="isSuccess">
      <div class="success-detail">
        {{ successText }}
        <p v-if="pageDataSource.isMediumPackage == 1 &&
          !pageDataSource.notLowerSale &&
          !pageDataSource.limitSaleNumber
          ">
          购买{{ detailsData.totalNumber }}个中包装，实际购买{{ Number(detailsData.totalNumber) * pageDataSource.mediumPackage }}
          {{ pageDataSource.sellUnit }}
        </p>
      </div>
    </div>

    <div class="enshrine-bg" v-if="isEnshrine">
      <div class="md-enshrine">{{ isText }}</div>
    </div>

    <VideoRedPacket :show="isstate" @close="isstate=false; productDetailsData()" :url="videodata.videoUrl" :id="videodata.videoId" />
    <addCarModal ref="addCarModalRef" :p_obj="p_obj"/>

    <!--过期/临期资质弹窗-->
    <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    <el-dialog :visible.sync="dl_modal" width="327px" :show-close="false">
        <supplier-ruler-pop :rulerData="dl_modal_obj" @popStatus="popStatus"></supplier-ruler-pop>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from "../../../components-v2/v2-dialog/index";
import VideoRedPacket from "../../../components-v2/product/VideoRedPacket.vue";
import PageFooter from "@/components-v2/layout/page-bottom";
import PageTop from "@/components-v2/layout/page-top";
import PageTop1 from "@/components-v2/layout/page-top1";
import { infoLabelList } from "./product-mock-data.js";
import { NumberInput } from "@/components-v2/number-input";
import { AccountStorage } from "@services/account";
import { defaultBuyNum } from '@/utils'
import numberInput from '@/mixin/number-input'
import qualificationsPopup from '@mixin/qualificationsPopup'

import { ActivityLine } from "@/components-v2/activity-line";
import ProductItem from "@/components-v2/product/product-item";
import historicalProductList from '@/components-v2/product/historicalProductList.vue'
import addCarModal from "@/components-v2/addCarModal/addCarModal";
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import { mapActions } from "vuex";
import supplierRulerPop from "@/components-v2/supplierRulerPop/index.vue";
import ProductActivityTags from '@/components-v2/ProductActivityTags.vue'
import ProductLabels from "@/components-v2/product/productLabels.vue";
import ProductPriceItem from '@/components-v2/ProductPriceItem.vue'

const accountStorage = new AccountStorage();

export default {
  components: {
    ProductPriceItem,
    ProductActivityTags,
    ProductLabels,
    supplierRulerPop,
    qualificationsDialog,
    PageFooter,
    PageTop,
    PageTop1,
    NumberInput,
    Dialog,
    VideoRedPacket,
    addCarModal,
    ActivityLine,
    ProductItem,
    historicalProductList
  },
  data() {
    return {
      isstate: false,
      videodata: {},
      isHover: true,
      isLogin: false,
      tabActive: -1,
      isShowBigBox: false,
      isShowfloat: false,
      productId: -1,
      activeTab: 0,
      bigPicture: "", //图片展示的大图
      imgArr: "", //多个图片的数据
      imgClickNum: 0, //多个图片的左右切换计数
      pageDataSource: {
        activityVOList: []
      },
      detailsData: {
        hotProduct: [],
        totalNumber: 1,
        limitSaleNumber: "",
        notLowerSale: ""
      },
      isDetai: "",
      percentObj: {
        percentX: 0,
        percentY: 0
      },
      // 商品详情的label
      infoLabelList,
      isOnHover: false,
      isShowTriangle: false,
      masterUrl: "",
      isSuccess: false, // 加入购物车成功后提示
      isEnshrine: false, // 收藏成功提示
      isText: "",
      successText: "",
      time: 3,
      isExternal: false,
      productDetail: null,
      instructionList: {},
      isOtherAccount: true,
      p_obj:{},
      dl_modal: false,
      dl_modal_obj: {},
      defaultImg:"https://obs.pujian.com/%E6%96%B0%E9%BB%98%E8%AE%A4%E5%95%86%E5%93%81%E5%9B%BE%E7%89%87.jpg"
    };
  },
  mixins: [numberInput, qualificationsPopup],
  async created() {
    this.productId = this.$route.params.id;
    await this.productDetailsData();
    // await this.leftImgActive();
    // this.getDetails();
    this.queryInstructions();
    // 判断活动的内容长度，是否展示更多的小三角
    if (this.$refs.activeSpan) {
      let width = this.$refs.activeSpan.offsetWidth;
      if (width >= 443) {
        this.isShowTriangle = true;
      }
    }
  },
  mounted() {
    let userInfo = accountStorage.getCacheUserInfo();
    if (userInfo) {
      let userId = userInfo.account;
      if (userId.charAt(0) == "8") {
        this.isOtherAccount = true;
      }
      this.isExternal = userInfo.type == 1;
    }
    this.getRecommend(this.productId);
  },
  computed: {
    invalidText() {
      let text = "";
      //无货 失效(下架 冻结 ) --- this.pageDataSource.ifFrozen ===0  正常   不等于0 失效
      if (JSON.stringify(this.pageDataSource) == "{}") {
        return (text = "");
      }
      if (
        (this.pageDataSource.ifFrozen && this.pageDataSource.ifFrozen !== 0) ||
        this.pageDataSource.status !== "0"
      ) {
        text = "商品已失效";
      } else if (this.pageDataSource.availableNum == "0") {
        return (text = "已售罄");
      }
      if (Number(this.pageDataSource.isMediumPackage) === 1) {
        if (
          Number(this.detailsData.totalNumber) *
          Number(this.pageDataSource.mediumPackage) >
          this.pageDataSource.availableNum
        ) {
          text = "库存不足";
        }
      } else {
        if (this.detailsData.totalNumber > this.pageDataSource.availableNum) {
          text = "库存不足";
        }
      }
      if (!this.pageDataSource.isInvalid) {
        text = "失效";
      }
      return text;
    },

    // 是否禁用加入购物车按钮
    disabledAddCart() {
      const a = this.isLogin;
      const b =
        (this.pageDataSource.ifFrozen && this.pageDataSource.ifFrozen !== 0) ||
        this.pageDataSource.status !== "0";
      let c = null;
      // 如果启用中包装，拿中包装数量*中包装管控 对比库存
      // 没有启用中包装，直接拿count 对比库存
      if (Number(this.pageDataSource.isMediumPackage) === 1) {
        c =
          Number(this.detailsData.totalNumber) *
          Number(this.pageDataSource.mediumPackage) >
          this.pageDataSource.availableNum;
      } else {
        c = this.detailsData.totalNumber > this.pageDataSource.availableNum;
      }
      return a && (b || c);
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.productId = to.params.id;
    this.productDetailsData();
    next();
  },
  methods: {
    ...mapActions(['findCardList']),
      //查看代理联系方式
      lookPhone(item) {
          this.dl_modal_obj = item;
          this.dl_modal = true;
      },
      popStatus(e){
          this.dl_modal = e
      },
    //去下载产品资料
    toDownLoad() {
      this.$router.push({ name: "v2-download" });
    },
    isDetailsData(val) {
      this.isDetai = val;
    },
    // ++
    handleSign(item) {
      this.isDetai = item.totalNumber;
    },
    onHoverEvent(isHover) {
      this.isOnHover = isHover;
    },
    leftImgActive() {
      this.bigPicture = this.pageDataSource.mainImageUrl;
      this.imgArr = this.pageDataSource.imageUrlList.slice(
        this.imgClickNum,
        this.imgClickNum + 3
      );
      this.imgClickNum = 3;
    },
    onMouseMaskIn(e,url) {
      this.bigPicture = url
      this.isShowBigBox = true;
      this.isShowfloat = true;
    },
    onMouseMaskOut() {
      this.isShowBigBox = false;
      this.isShowfloat = false;
    },
    // 商品图片放大逻辑
    onMouseMaskMove(_event) {
      let left = _event.clientX - this.$refs.smallBox.offsetLeft - 75;
      let top = _event.clientY - this.$refs.smallBox.offsetTop - 75;
      let objFloatBoxItem = this.$refs.objFloatBox;
      if (left < 0) {
        left = 0;
      } else if (_event && _event.toElement && left > _event.toElement.offsetWidth - objFloatBoxItem.offsetWidth) {
        left = _event.toElement.offsetWidth - objFloatBoxItem.offsetWidth;
      }

      if (top < 0) {
        top = 0;
      } else if (_event && _event.toElement && top > _event.toElement.offsetHeight - objFloatBoxItem.offsetHeight) {
        top = _event.toElement.offsetHeight - objFloatBoxItem.offsetHeight;
      }
      objFloatBoxItem.style.left = left + "px";
      objFloatBoxItem.style.top = top + "px";
      this.percentObj.percentX = left / (_event.toElement.offsetWidth - objFloatBoxItem.offsetWidth);
      this.percentObj.percentY = top / (_event.toElement.offsetHeight - objFloatBoxItem.offsetHeight);
      let objBigBoxImageItem = this.$refs.objBigBoxImage;
      let objBigBoxItem = this.$refs.objBigBox;
      objBigBoxImageItem.style.left = -this.percentObj.percentX * ( objBigBoxImageItem.offsetWidth - objBigBoxItem.offsetWidth ) + "px";
      objBigBoxImageItem.style.top = -this.percentObj.percentY * ( objBigBoxImageItem.offsetHeight - objBigBoxItem.offsetHeight ) + "px";
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
    },
    async addCardSubmit() {
      if (this.verifySpecialOfferChooseNum()) return;
      if (this.addCardVerify()) return;

      let flag =
        this.pageDataSource.ifFrozen &&
        this.pageDataSource.ifFrozen === 0 &&
        this.pageDataSource.status === "0";
      if (!this.isLogin) {
        this.$router.push({ path: "/account/login" });
      } else if (!flag) {
        if (
          (this.pageDataSource.ifFrozen &&
            this.pageDataSource.ifFrozen !== 0) ||
          this.pageDataSource.status !== "0"
        ) {
          // 无效商品
          return;
        } else if (Number(this.pageDataSource.availableNum) === 0) {
          return;
          // 无货商品
        }
        if (
          this.pageDataSource.notLowerSale != 0 &&
          this.pageDataSource.limitSaleNumber != 0
        ) {
          if (this.detailsData.totalNumber < this.pageDataSource.notLowerSale) {
            this.successText = "您输入的购买数量小于最低数量要求！";
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 2000);
            return;
          } else if (
            this.detailsData.totalNumber > this.pageDataSource.limitSaleNumber
          ) {
            this.successText = "您输入的购买数量大于最多数量限制！";
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 2000);
            return;
          } else {
            this.successText = "已加入购物车";
          }
        } else {
          this.successText = "已加入购物车";
        }

        const URL = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
        const data = {
          productId: this.productId,
          userId: Number(this.getUserId()),
          totalNumber: this.detailsData.totalNumber
        };
        const result = await this.$getHttpClient().post(URL, data);
        if (result.data.code === "200") {
          let { data } = result.data;
          // 如果有资质过期的数据，显示资质过期弹窗
          if (data.certificateList && data.certificateList.length) {
            this.qualificationsPopup(2, data)
            return
          }

          this.findCardList();
          this.isSuccess = true;
          setTimeout(() => {
            this.isSuccess = false;
          }, 2000);
        } else if(result.data.code == "8501"){
            this.p_obj = result.data.payload;
            this.$refs.addCarModalRef.showModal()
        }
      }
    },
    addCart() {
      if (this.qualificationsPopup(1)) {
        return;
      }
        if (this.pageDataSource.lastReturnTime == '不可退货' && this.pageDataSource.isMiddlePack == 1 && this.pageDataSource.mediumPackage && this.pageDataSource.mediumPackage > 0){
            const packageAmount = this.detailsData.totalNumber * this.pageDataSource.mediumPackage;
            const h = this.$createElement;
            this.$msgbox({
                title: '温馨提示',
                message: h('p', null, [
                    h('span', null, '该商品为中包装，1中包装='),
                    h('span', null, ` ${this.pageDataSource.mediumPackage}/${this.pageDataSource.sellUnit}`),
                    h('p', null, [
                        h('span', null, `本次购买 `),
                        h('span', { style: 'color: red;font-weight: bold;' }, `${packageAmount}/${this.pageDataSource.sellUnit}`)
                    ]),
                    h('span', null, '并且当前批次【不可退货】，确认购买吗?'),
                ],),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(action => {
                this.addCardSubmit();
            }).catch(()=>{});
        } else if (this.pageDataSource.lastReturnTime == '不可退货') {
            this.$confirm("当前批次【不可退货】，确认购买吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            }).then(() => {
                this.addCardSubmit();
            }).catch(() => {
            })
        } else if(this.pageDataSource.isMiddlePack == 1 && this.pageDataSource.mediumPackage && this.pageDataSource.mediumPackage > 0){
            const packageAmount = this.detailsData.totalNumber * this.pageDataSource.mediumPackage;
            const h = this.$createElement;
            this.$msgbox({
                title: '温馨提示',
                message: h('p', null, [
                    h('span', null, '该商品为中包装，1中包装='),
                    h('span', null, ` ${this.pageDataSource.mediumPackage}/${this.pageDataSource.sellUnit}`),
                    h('p', null, [
                        h('span', null, `本次购买 `),
                        h('span', { style: 'color: red;font-weight: bold;' }, `${packageAmount}/${this.pageDataSource.sellUnit}`)
                    ]),
                ],),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(action => {
                this.addCardSubmit();
            }).catch(()=>{});
        }else{
            this.addCardSubmit();
        }
    },
    //处方类型
    prescriptionCategory(val) {
      let obj = {};
      switch (val) {
        case "8906":
          obj.text = "OTC";
          obj.bgColor = "green";
          obj.type = "乙";
          break;
        case "8905":
          obj.text = "OTC";
          obj.bgColor = "red";
          obj.type = "甲";
          break;
        case "8904":
          obj.text = "RX";
          obj.bgColor = "red";
          break;
        default:
          break;
      }
      return obj;
    },
    //有效期
    setFfectivePeriod(obj) {
      if (obj && obj.length > 10) {
        obj = obj.slice(0, 10);
      }
      return obj;
    },
    async productDetailsData() {
      const URL = `/pjyy-deepexi-product-center/api/v2/product/PcProductCommon/detail?id=${this.productId}`;
      let result = await this.$getHttpClient().get(URL);
      if (result.data.code === "100001") {
        this.isLogin = true;
      }
      if (result.data.data) {
        result.data.data.isMiddlePack = result.data.data.isMediumPackage
      }
      this.videodata = result.data.data || {};
      let dataSource = result.data.data || {};
      this.bigPicture = result?.data?.data?.mainImageUrl

      this.detailsData.limitSaleNumber = dataSource.limitSaleNumber;
      this.detailsData.notLowerSale = dataSource.notLowerSale;
      this.detailsData.totalNumber = this.$route.params.sale;

      this.pageDataSource = {
        ...dataSource,
        ption: dataSource.prescription,
        control: dataSource.isMediumPackage
      };

      this.pageDataSource.availableNum = Number(this.pageDataSource.availableNum);
      if (this.pageDataSource) {
        this.pageDataSource.nearEffectivePeriod = this.setFfectivePeriod(this.pageDataSource.nearEffectivePeriod);
        this.pageDataSource.farEffectivePeriod = this.setFfectivePeriod(this.pageDataSource.farEffectivePeriod);
        if (dataSource.prescription) {
          this.pageDataSource.prescription = this.prescriptionCategory(dataSource.prescription);
        }
        if (this.pageDataSource.unitPrice === null) {
          this.isLogin = false;
        } else {
          this.isLogin = true;
          this.pageDataSource.unitPrice = dataSource.unitPrice;
          let { numberOne, sellUnit, moneyOne } = dataSource;
          let moneyOneStr = (Number(moneyOne) || 0).toFixed(2);
          this.pageDataSource.active = numberOne ? `满${numberOne}${sellUnit}减${moneyOneStr}元` : '';
          dataSource.availableNum = Number(dataSource.availableNum);
          this.pageDataSource.availableNum = dataSource.availableNum;
        }
      }
    },
    // 点击放大缩略图
    previewPictures(url) {
      this.bigPicture = url;
    },
    toSwichPicture(type) {
      if (type === "left") {
        if (this.imgClickNum > 3) {
          this.imgClickNum = this.imgClickNum - 3;
          this.imgArr = this.pageDataSource.imageUrlList.slice(
            this.imgClickNum - 3,
            this.imgClickNum
          );
        }
      } else {
        if (this.imgClickNum < this.pageDataSource.imageUrlList.length) {
          this.imgArr = this.pageDataSource.imageUrlList.slice(
            this.imgClickNum,
            this.imgClickNum + 3
          );
          this.imgClickNum = this.imgClickNum + 3;
        }
      }
    },
    // 点击收藏
    async hanndleEnshrine() {
      if (this.pageDataSource.isCollect) {
        let url = `/pjyy-deepexi-product-center/api/v1/product/productCollection/remove?id=${this.pageDataSource.id}`;
        this.$getHttpClient()
          .delete(url)
          .then(res => {
            if (res.data.code === "200") {
              this.pageDataSource.isCollect = !this.pageDataSource.isCollect;
              this.isText = "已取消收藏商品";
              this.isEnshrine = true;
              setTimeout(() => {
                this.isEnshrine = false;
              }, 1000);
            }
          });
      } else {
        let data = {
          productId: this.productId
        };
        let url = `/pjyy-deepexi-product-center/api/v1/product/productCollection/addCollect`;
        let result = await this.$getHttpClient().post(url, data);
        if (result.data.code === "200") {
          this.isText = "已收藏商品";
          this.isEnshrine = true;
          this.pageDataSource.isCollect = !this.pageDataSource.isCollect;
          setTimeout(() => {
            this.isEnshrine = false;
          }, 1000);
        }
      }
    },
    async handelStockout(item) {
      let data = {
        productId: item.id
      };
      let url = `/pjyy-deepexi-product-center/api/v1/product/pcRegister/addRegister`;
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$messageTip("提醒设置成功,商品到货后提醒你", "success");
            this.pageDataSource.isCheckArrive = true;
          }
        });
    },
    //取消提醒
    async deleteRemind(item) {
      let data = {
        productId: item.id
      };
      let url = "/pjyy-deepexi-product-center/api/v1/product/pcRegister/deleteRegister";
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$messageTip("已取消到货提醒", "success");
            this.pageDataSource.isCheckArrive = false;
          }
        });
    },
    getDetails() {
      let url = `/pjyy-deepexi-product-center/api/v1/product/PcProductContent/pc/getDetailByProductId?productId=${this.productId}`;
      this.$getHttpClient()
        .get(url)
        .then(res => {
          if (res.data.code === "200" && res.data.data) {
            this.productDetail = res.data.data;
            document.all.detail.innerHTML = this.productDetail.pcContent;
          }
        });
    },
    queryInstructions() {
      let url = `/pjyy-deepexi-product-center/api/v1/product/instruction/getProductManualDetail?productId=${this.productId}`;
      this.$getHttpClient()
        .get(url)
        .then(res => {
          if (res.data.code === "200" && res.data.data) {
            this.instructionList = res.data.data;
          }
        });
    },
    async getRecommend(productCode) {
      const res = await this.$getHttpClient().post(
        "/pjyy-deepexi-product-center/api/v2/product/PcProductCommon/relation",
        {
          productCode: productCode
        }
      );
      if (res.data.code === "200") {
        this.detailsData.hotProduct = defaultBuyNum(res.data.data)
        if (this.detailsData.hotProduct.length > 5) {
          this.detailsData.hotProduct = this.detailsData.hotProduct.slice(0, 5);
        }
      }
    }
  }
};
</script>
<style src="./style/product-details.less" lang="less" scoped></style>
